document.addEventListener('DOMContentLoaded', function() {
    // Check if the current path is the homepage/root domain
    if (window.location.pathname === '/' || window.location.pathname === '') {
        var button = document.querySelector('main .banner__buttons a.button.button--primary[href="/pages/contact"]');
        
        if (button) {
            button.setAttribute('data-token', '361393c05f17465a8109ab2ff158bc92');
            button.setAttribute('data-orgname', 'FixdUp-Handyman-Services');
            button.setAttribute('onClick', 'HCPWidget.openModal()');
            button.setAttribute('href', '/#');
            button.classList.add('hcp-button');
        }
    }
});